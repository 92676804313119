import { apiAxios } from "./apiService";

export function getAuthLink(linkMode: "authenticate" | "authorize") {
  return apiAxios.get<string>(`auth/twitter/request-token?linkMode=${linkMode}`);
}

export async function getAccessToken(
  oauth_token: string,
  oauth_verifier: string
): Promise<{ oauth_token: string; oauth_token_secret: string }> {
  const loginResult: { oauth_token: string; oauth_token_secret: string } = (
    await apiAxios.get(`auth/twitter/access-token?oauth_token=${oauth_token}&oauth_verifier=${oauth_verifier}`)
  ).data;
  return loginResult;
}
