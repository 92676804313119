
import { computed, defineComponent, ref } from "vue";
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue";
import { CheckIcon, SelectorIcon, RefreshIcon } from "@heroicons/vue/solid";
import { PlusIcon } from "@heroicons/vue/outline";
import { useStore } from "../store";
import { Dispatcher } from "../store/actions";
import { ContentLoader } from "vue-content-loader";
import { List } from "../models/list";

const initialSelected = {
  id: "-1",
  name: "loading...",
  memberCount: 0,
  members: [],
  isTracked: false,
} as List;

export default defineComponent({
  props: ["searchText"],
  components: {
    ContentLoader,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    SelectorIcon,
    PlusIcon,
    RefreshIcon,
  },
  setup(props) {
    const store = useStore();
    const dispatcher = new Dispatcher(store);

    const open = ref(false);
    const initialLoading = ref(true);

    const selected = ref(initialSelected);

    const filterUsers = (list: List) => {
      const allMembers = list?.members ?? [];
      if (!props.searchText) {
        return allMembers;
      }

      const searchLower = props.searchText.toLowerCase();
      return allMembers.filter((m) => m.name.toLowerCase().includes(searchLower) || m.username.toLowerCase().includes(searchLower));
    };

    return {
      loading: computed(() => store.state.loadingLists),
      initialLoading,
      open,
      form: {
        twitterHandle: "",
      },
      dispatcher,
      initialSelected,
      lists: computed(() => store.state.lists),
      hasLists: computed(() => store.state.lists && store.state.lists?.length > 0),
      selected,
      tracked: computed(() => store.state.trackedList),
      selectedMembers: computed(() => filterUsers(selected.value)),
      trackedMembers: computed(() => filterUsers(store.state.trackedList as List)),
      user: computed(() => store.state.userInfo),
    };
  },
  created() {
    this.dispatcher.fetchLists().then(() => (this.selected = ((this.lists && this.lists[0]) as List) ?? this.initialSelected));
    this.initialLoading = false;
  },
  methods: {
    async trackSelectedList() {
      await this.dispatcher.trackList(this.selected);
      this.dispatcher.showNotification({ heading: "Success!", message: "Please wait up to 15 minutes to see tweets from this List" }, 3000);
    },
    async refreshLists() {
      console.log("refreshing lists");
      this.dispatcher.fetchLists().then(() => (this.selected = ((this.lists && this.lists[0]) as List) ?? this.initialSelected));
    },
  },
});
