import { InjectionKey } from "vue";
import { createStore, Store as VuexStore, useStore as baseUseStore } from "vuex";
import { defaultState, State } from "./state";
import { Getters, getters } from "./getters";
import { actions, Dispatcher } from "./actions";
import { mutations } from "./mutations";

export const key: InjectionKey<VuexStore<State>> = Symbol();

export function useStore(): Store {
  return baseUseStore(key);
}

export { Dispatcher };

export type Store = Omit<VuexStore<State>, "getters"> & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
};

export const store = createStore<State>({
  state: defaultState(),
  getters,
  mutations,
  actions,
});

// quick and dirty strong typing
export const typedStore = store as Store;
