import { RouteLocationNormalized, NavigationGuardNext, RouteLocationRaw } from "vue-router";
import { typedStore } from "@/store";

export default function requireAuth(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void {
  if (typedStore.getters.authenticated) {
    next();
  } else {
    const location = { path: "/sign-in", query: { from: to.path } } as RouteLocationRaw;
    next(location);
  }
}
