
import { defineComponent } from "vue";
import { Dispatcher, useStore } from "../store";

export default defineComponent({
  name: "Landing",
  setup() {
    const dispatcher = new Dispatcher(useStore());
    dispatcher.generateAuthLink();
  },
});
