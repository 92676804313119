import { RouteLocationNormalized, NavigationGuardNext, RouteLocationRaw } from "vue-router";
import { typedStore, Dispatcher } from "@/store";

export default async function canHaveMoreUsers(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> {
  const dispatcher = new Dispatcher(typedStore);
  await dispatcher.fetchCanHaveMoreUsers();

  if (typedStore.state.canHaveMoreUsers) {
    next();
  } else {
    const location = { path: "/register/keep-in-touch" } as RouteLocationRaw;
    next(location);
  }
}
