<template>
  <div class="sticky top-0 z-10 flex-shrink-0 h-16 bg-white border-b border-gray-200 flex">
    <button
      type="button"
      class="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 md:hidden"
      @click="$emit('openSidebar')"
    >
      <span class="sr-only">Open sidebar</span>
      <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
    </button>
    <div class="flex-1 flex justify-between px-4 md:px-0">
      <div class="flex-1 flex">
        <form class="w-full flex md:ml-0" action="#" method="GET">
          <label for="search-field" class="sr-only">Search</label>
          <div class="relative w-full text-gray-400 focus-within:text-gray-600">
            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center">
              <SearchIcon class="h-5 w-5" aria-hidden="true" />
            </div>
            <input
              @input="$emit('searchTextChanged', $event.target.value)"
              id="search-field"
              class="
                block
                h-full
                w-full
                border-transparent
                py-2
                pl-8
                pr-3
                text-gray-900
                placeholder-gray-500
                focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent
                sm:text-sm
              "
              placeholder="Search"
              type="search"
              name="search"
            />
          </div>
        </form>
        <img src="../assets/logo-boxed-r.svg" alt="logo" class="sm:hidden h-8 w-8 self-center ml-4" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { MenuAlt2Icon } from "@heroicons/vue/outline";
import { SearchIcon } from "@heroicons/vue/solid";

export default defineComponent({
  name: "TopBar",
  emits: ["openSidebar", "searchTextChanged"],
  components: {
    MenuAlt2Icon,
    SearchIcon,
  },
});
</script>
