<!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
  <!--
    This example requires updating your template:

    ```
    <html class="h-full bg-gray-50">
    <body class="h-full">
    ```
  -->
  <div class="min-h-full flex sm:items-center justify-center pt-24 sm:py-12 px-4 sm:px-6 lg:px-8 bg-gray-50 h-screen">
    <div class="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full" aria-hidden="true">
      <div class="relative h-full max-w-7xl mx-auto">
        <svg
          class="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2"
          width="404"
          height="784"
          fill="none"
          viewBox="0 0 404 784"
        >
          <defs>
            <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="784" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
        </svg>
        <svg
          class="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2"
          width="404"
          height="784"
          fill="none"
          viewBox="0 0 404 784"
        >
          <defs>
            <pattern id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="784" fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)" />
        </svg>
      </div>
    </div>

    <div class="max-w-md w-full space-y-8">
      <div class="relative">
        <img class="mx-auto h-16 w-auto" src="../assets/logo-horizontal.svg" alt="rkive" />
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
        <p class="mt-2 text-center text-sm text-gray-600">
          Or
          {{ " " }}
          <router-link to="/register" class="font-medium text-blue-600 hover:text-blue-500 z-50"> sign-up here </router-link>
        </p>
      </div>
      <form class="mt-8 space-y-6" action="#" @submit.prevent="submit">
        <input type="hidden" name="remember" value="true" />
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label for="email-address" class="sr-only">Email</label>
            <input
              id="email-address"
              name="email"
              type="email"
              autocomplete="email"
              required=""
              class="
                appearance-none
                rounded-none
                relative
                block
                w-full
                px-3
                py-2
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-t-md
                focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10
                sm:text-sm
              "
              placeholder="Email"
              v-model="form.email"
            />
          </div>
          <div>
            <label for="password" class="sr-only">Password</label>
            <input
              id="password"
              name="password"
              type="password"
              autocomplete="current-password"
              required=""
              class="
                appearance-none
                rounded-none
                relative
                block
                w-full
                px-3
                py-2
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-b-md
                focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10
                sm:text-sm
              "
              placeholder="Password"
              v-model="form.password"
            />
          </div>
        </div>

        <div class="flex items-center justify-between hidden">
          <div class="flex items-center">
            <input
              id="remember-me"
              name="remember-me"
              type="checkbox"
              class="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label for="remember-me" class="ml-2 block text-sm text-gray-900"> Remember me </label>
          </div>

          <div class="text-sm">
            <a href="#" class="font-medium text-blue-600 hover:text-blue-500"> Forgot your password? </a>
          </div>
        </div>

        <div>
          <button
            type="submit"
            class="
              group
              relative
              w-full
              flex
              justify-center
              py-2
              px-4
              border border-transparent
              text-sm
              font-medium
              rounded-md
              text-white
              bg-blue-600
              hover:bg-blue-700
              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
            "
          >
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <LockClosedIcon class="h-5 w-5 text-blue-500 group-hover:text-blue-400" aria-hidden="true" />
            </span>
            <span v-if="!spin">Continue</span>
            <RefreshIcon v-if="spin" class="h-5 w-5 animate-spin" />
          </button>
        </div>
      </form>
      <div class="mt-6" v-if="!!authLink">
        <div class="relative">
          <div class="absolute inset-0 flex items-center">
            <div class="w-full border-t border-gray-300" />
          </div>
          <div class="relative flex justify-center text-sm">
            <span class="px-2 bg-gray-50 text-gray-500"> Or </span>
          </div>
        </div>

        <div class="mt-6">
          <div>
            <a
              :href="authLink"
              class="
                relative
                w-full
                inline-flex
                justify-center
                py-2
                px-4
                border border-gray-300
                rounded-md
                shadow-sm
                bg-white
                text-sm
                font-medium
                text-gray-500
                hover:bg-gray-50
              "
            >
              <span class="sr-only">Continue with Twitter</span>
              <svg class="w-5 h-5 mr-2" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                <path
                  d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84"
                />
              </svg>
              Continue with Twitter
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import { useStore } from "../store";
import { LockClosedIcon, RefreshIcon } from "@heroicons/vue/solid";
import { Dispatcher } from "../store/actions";

export default defineComponent({
  name: "SignIn",
  components: {
    LockClosedIcon,
    RefreshIcon,
  },
  setup() {
    const store = useStore();
    const dispatcher = new Dispatcher(store);
    const spin = ref(false);

    return {
      signIn: (email, password) => dispatcher.signIn(email, password),
      authLink: computed(() => store.state.authLink),
      form: {
        email: "",
        password: "",
      },
      error: null,
      spin,
    };
  },
  methods: {
    submit() {
      this.spin = true;
      this.signIn(this.form.email, this.form.password)
        .then(() => this.$router.push(this.$route.query.from || "/screenshots"))
        .catch((err) => (this.error = err.message))
        .finally(() => (this.spin = false));
    },
  },
});
</script>
