<template>
  <div>
    <!-- mobile sidebar -->
    <TransitionRoot as="template" :show="show">
      <Dialog as="div" class="fixed inset-0 z-40 flex md:hidden" @close="$emit('hide')">
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div class="relative max-w-xs w-full bg-white pt-5 pb-4 flex-1 flex flex-col">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  class="
                    ml-1
                    flex
                    items-center
                    justify-center
                    h-10
                    w-10
                    rounded-full
                    focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white
                  "
                  @click="$emit('hide')"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="flex-shrink-0 px-4 flex items-center">
              <img class="h-12 w-auto" src="../assets/logo-horizontal.svg" alt="rkive" />
            </div>
            <div class="mt-5 flex-1 h-0 overflow-y-auto">
              <nav class="px-2 space-y-1">
                <router-link
                  v-for="item in navigation"
                  :key="item.name"
                  :to="item.href"
                  :class="[
                    item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                    'group rounded-md py-2 px-2 flex items-center text-base font-medium',
                  ]"
                >
                  <component
                    :is="item.icon"
                    :class="[item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-4 flex-shrink-0 h-6 w-6']"
                    aria-hidden="true"
                  />
                  {{ item.name }}
                </router-link>
              </nav>
            </div>

            <!-- Profile section -->
            <router-link to="/sign-out" class="flex-shrink-0 w-full block">
              <div class="flex-shrink-0 flex border-t border-gray-200 p-4 group hover:bg-gray-50">
                <div class="flex items-center">
                  <div>
                    <LogoutIcon class="mr-3 flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                  </div>
                  <div class="ml-3">
                    <p class="text-sm text-left font-medium text-gray-700 group-hover:text-gray-900">Sign out</p>
                    <p class="text-xs text-left font-medium text-gray-500 group-hover:text-gray-700 truncate">@{{ username }}</p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 border-r border-gray-200 bg-white">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="pt-5 flex flex-col flex-grow overflow-y-auto">
        <div class="flex-shrink-0 px-4 flex items-center">
          <img class="h-12 w-auto" src="../assets/logo-horizontal.svg" alt="rkive" />
        </div>
        <div class="flex-grow mt-5 flex flex-col">
          <nav class="flex-1 px-2 pb-4 space-y-1">
            <router-link
              v-for="item in navigation"
              :key="item.name"
              :to="item.href"
              :class="[
                item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                'group rounded-md py-2 px-2 flex items-center text-sm font-medium',
              ]"
            >
              <component
                :is="item.icon"
                :class="[item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']"
                aria-hidden="true"
              />
              {{ item.name }}
            </router-link>
          </nav>
        </div>
      </div>

      <!-- Profile section -->
      <router-link to="/sign-out" class="flex-shrink-0 w-full block">
        <div class="flex-shrink-0 flex border-t border-gray-200 p-4 group hover:bg-gray-50">
          <div class="flex items-center">
            <div>
              <LogoutIcon class="mr-3 flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
            </div>
            <div class="ml-3">
              <p class="text-sm text-left font-medium text-gray-700 group-hover:text-gray-900">Sign out</p>
              <p class="text-xs text-left font-medium text-gray-500 group-hover:text-gray-700 truncate">@{{ username }}</p>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { HomeIcon, UsersIcon, XIcon, LogoutIcon } from "@heroicons/vue/outline";
import { useStore } from "../store";

const navOptions = [
  { name: "Screenshots", href: "/screenshots", icon: HomeIcon, current: false },
  { name: "Tracked Lists", href: "/tracked-lists", icon: UsersIcon, current: false },
];

export default defineComponent({
  name: "SideBar",
  props: ["show"],
  emits: ["hide"],
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    XIcon,
    LogoutIcon,
  },
  setup() {
    const store = useStore();

    return {
      navigation: computed(() => navOptions.map((n) => ({ ...n, current: useRoute().path === n.href }))),
      username: computed(() => store.state.userInfo.twitterUsername),
    };
  },
});
</script>
