import { List } from "@/models/list";
import { Tweet } from "@/models/tweet";
import { filter } from "lodash";
import { UserV2 } from "twitter-api-v2";
import { MutationTree } from "vuex";
import { defaultState, UserInfo, State, StateNotification } from "./state";

export const mutationTypes = {
  // AUTH
  SET_USER: "SET_USER",
  SET_ID_TOKEN: "SET_ID_TOKEN",
  SET_CAN_HAVE_MORE_USERS: "SET_CAN_HAVE_MORE_USERS",
  SET_USER_INFO: "SET_USER_INFO",
  SET_AUTH_LINK: "SET_AUTH_LINK",

  // LISTS
  SET_LISTS: "SET_LISTS",
  SET_TRACKED_LIST: "SET_TRACKED_LIST",
  START_LOADING_LISTS: "START_LOADING_LISTS",
  END_LOADING_LISTS: "END_LOADING_LISTS",

  // TWEETERS
  SET_TRACKED_USERS: "SET_TRACKED_USERS",
  ADD_TRACKED_USER: "ADD_TRACKED_USER",
  REMOVE_TRACKED_USER: "REMOVE_TRACKED_USER",
  START_LOADING_TWEETERS: "START_LOADING_TWEETERS",
  END_LOADING_TWEETERS: "END_LOADING_TWEETERS",

  // TWEETS
  SET_TWEETS: "SET_TWEETS",
  SET_NEXT_PAGE_TOKEN: "SET_NEXT_PAGE_TOKEN",
  START_LOADING_TWEETS: "START_LOADING_TWEETS",
  END_LOADING_TWEETS: "END_LOADING_TWEETS",
  SET_NO_MORE_TWEETS: "SET_NO_MORE_TWEETS",

  // GLOBAL
  RESET_STATE: "RESET_STATE",
  START_NOTIFICATION: "START_NOTIFICATION",
  END_NOTIFICATION: "END_NOTIFICATION",
};

export const mutations: MutationTree<State> = {
  // AUTH
  [mutationTypes.SET_USER](state, user) {
    state.authUser.user = user;
  },
  [mutationTypes.SET_ID_TOKEN](state, token: string) {
    state.authUser.idToken = token;
  },
  [mutationTypes.SET_CAN_HAVE_MORE_USERS](state, canHaveMore: boolean) {
    state.canHaveMoreUsers = canHaveMore;
  },
  [mutationTypes.SET_USER_INFO](state, userInfo: UserInfo) {
    state.userInfo = userInfo;
  },
  [mutationTypes.SET_AUTH_LINK](state, authLink: string) {
    state.authLink = authLink;
  },

  // LISTS
  [mutationTypes.SET_LISTS](state, lists: List[]) {
    state.lists = lists;
  },
  [mutationTypes.SET_TRACKED_LIST](state, list: List) {
    state.trackedList = list;
  },
  [mutationTypes.START_LOADING_LISTS](state) {
    state.loadingLists = true;
  },
  [mutationTypes.END_LOADING_LISTS](state) {
    state.loadingLists = false;
  },

  // TWEETERS
  [mutationTypes.SET_TRACKED_USERS](state, trackedUsers: UserV2[]) {
    state.trackedUsers = trackedUsers;
  },
  [mutationTypes.ADD_TRACKED_USER](state, trackedUser: UserV2) {
    state.trackedUsers = [...state.trackedUsers, trackedUser];
  },
  [mutationTypes.REMOVE_TRACKED_USER](state, twitterId: string) {
    state.trackedUsers = filter(state.trackedUsers, (u) => u.id !== twitterId);
  },
  [mutationTypes.START_LOADING_TWEETERS](state) {
    state.loadingTweeters = true;
  },
  [mutationTypes.END_LOADING_TWEETERS](state) {
    state.loadingTweeters = false;
  },

  // TWEETS
  [mutationTypes.SET_TWEETS](state, tweets: Tweet[]) {
    state.tweets = tweets;
  },
  [mutationTypes.SET_NEXT_PAGE_TOKEN](state, nextPage: string) {
    state.tweetsNextPage = nextPage;
  },
  [mutationTypes.START_LOADING_TWEETS](state) {
    state.loadingTweets = true;
  },
  [mutationTypes.END_LOADING_TWEETS](state) {
    state.loadingTweets = false;
  },
  [mutationTypes.SET_NO_MORE_TWEETS](state) {
    state.noMoreTweets = true;
  },

  // MISC
  [mutationTypes.RESET_STATE](state) {
    state = defaultState();
  },
  [mutationTypes.START_NOTIFICATION](state, notification: StateNotification) {
    state.notification = notification;
  },
  [mutationTypes.END_NOTIFICATION](state) {
    state.notification = null;
  },
};
