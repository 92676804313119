import { ActionTree } from "vuex";
import { mutationTypes } from "./mutations";
import { UserInfo, State, StateNotification, AuthUser } from "./state";
import { authenticatedApiAxios as axios } from "../services/apiService";
import { UserV2 } from "twitter-api-v2";
import {
  getAuth,
  signInWithCredential,
  signInWithEmailAndPassword,
  TwitterAuthProvider,
  User,
  linkWithCredential,
  OAuthCredential,
  UserCredential,
  signOut,
} from "firebase/auth";
import { Store } from ".";
import { EmailSignUpResult } from "@/models/emailSignUpResult";
import { getAccessToken, getAuthLink } from "@/services/twitterService";
import { List } from "@/models/list";
import { TweetQueryResult } from "@/models/tweet-query-result";
import { Tweet } from "@/models/tweet";
import { Getters } from "./getters";
import { apiUrl } from "@/config";

/* eslint-disable  @typescript-eslint/no-explicit-any */

const types = {
  // AUTH
  FETCH_USER: "FETCH_USER",
  FETCH_USER_INFO: "FETCH_USER_INFO",
  UPDATE_TOKEN: "UPDATE_TOKEN",
  REFRESH_STALE_TOKEN: "REFRESH_STALE_TOKEN",
  SIGN_IN: "SIGN_IN",
  SIGN_OUT: "SIGN_OUT",
  FETCH_CAN_HAVE_MORE_USERS: "FETCH_CAN_HAVE_MORE_USERS",
  SIGN_UP_WITH_EMAIL: "SIGN_UP_WITH_EMAIL",
  SIGN_UP_WITH_TWITTER: "SIGN_UP_WITH_TWITTER",
  SIGN_IN_WITH_TWITTER: "SIGN_IN_WITH_TWITTER",
  LINK_TWITTER: "LINK_TWITTER",
  TWITTER_AUTH_POST_HANDLING: "TWITTER_AUTH_POST_HANDLING",
  GENERATE_AUTH_LINK: "GENERATE_AUTH_LINK",

  // TWEETERS
  FETCH_TRACKED_TWEETERS: "FETCH_TRACKED_TWEETERS",
  START_TRACKING_USER: "START_TRACKING_USER",
  STOP_TRACKING_USER: "STOP_TRACKING_USER",

  // LISTS
  FETCH_LISTS: "FETCH_LISTS",
  TRACK_LIST: "TRACK_LIST",

  // SCREENSHOTS
  FETCH_TWEETS: "FETCH_TWEETS",
  REPORT_MISSING_SCREENSHOT: "REPORT_MISSING_SCREENSHOT",

  // GLOBAL
  SHOW_NOTIFICATION: "SHOW_NOTIFICATION",
  HIDE_NOTIFICATION: "HIDE_NOTIFICATION",
};

export class Dispatcher {
  constructor(private store: Store) {}

  // AUTH
  fetchUser = async (user: User | null): Promise<any> => await this.store.dispatch(types.FETCH_USER, user);
  fetchUserInfo = async (): Promise<any> => await this.store.dispatch(types.FETCH_USER_INFO);
  updateToken = async (user: User | null): Promise<any> => await this.store.dispatch(types.UPDATE_TOKEN, user);
  refreshToken = async (): Promise<any> => await this.store.dispatch(types.REFRESH_STALE_TOKEN);
  signIn = async (email: string, password: string): Promise<any> => await this.store.dispatch(types.SIGN_IN, { email, password });
  signOut = async (): Promise<any> => await this.store.dispatch(types.SIGN_OUT);
  fetchCanHaveMoreUsers = async (): Promise<any> => await this.store.dispatch(types.FETCH_CAN_HAVE_MORE_USERS);
  signUpWithEmail = async (email: string, password: string): Promise<any> =>
    await this.store.dispatch(types.SIGN_UP_WITH_EMAIL, { email, password });
  signUpWithTwitter = async (oauth_token: string, oauth_verifier: string): Promise<any> =>
    await this.store.dispatch(types.SIGN_UP_WITH_TWITTER, { oauth_token, oauth_verifier });
  signInWithTwitter = async (oauth_token: string, oauth_verifier: string): Promise<any> =>
    await this.store.dispatch(types.SIGN_IN_WITH_TWITTER, { oauth_token, oauth_verifier });
  linkTwitter = async (oauth_token: string, oauth_verifier: string): Promise<any> =>
    await this.store.dispatch(types.LINK_TWITTER, { oauth_token, oauth_verifier });
  generateAuthLink = async (): Promise<any> => await this.store.dispatch(types.GENERATE_AUTH_LINK);

  // LISTS
  fetchLists = async (): Promise<void> => await this.store.dispatch(types.FETCH_LISTS);
  trackList = async (list: List): Promise<void> => await this.store.dispatch(types.TRACK_LIST, list);

  // TWEETERS
  fetchTrackedTweeters = async (): Promise<any> => await this.store.dispatch(types.FETCH_TRACKED_TWEETERS);
  startTrackingUser = async (twitterUsername: string): Promise<any> =>
    await this.store.dispatch(types.START_TRACKING_USER, twitterUsername);
  stopTrackingUser = async (twitterId: string): Promise<any> => await this.store.dispatch(types.STOP_TRACKING_USER, twitterId);

  // SCREENSHOTS
  fetchScreenshots = async (): Promise<number> => await this.store.dispatch(types.FETCH_TWEETS);
  reportMissingScreenshot = async (tweet: Tweet): Promise<any> => await this.store.dispatch(types.REPORT_MISSING_SCREENSHOT, tweet);

  // GLOBAL
  showNotification = async (notification: StateNotification, milliseconds: number): Promise<any> =>
    await this.store.dispatch(types.SHOW_NOTIFICATION, { notification, milliseconds });
  hideNotification = async (): Promise<any> => await this.store.dispatch(types.HIDE_NOTIFICATION);
}

export const actions: ActionTree<State, State> = {
  // AUTH
  async [types.FETCH_USER]({ commit, dispatch }, user: User | null) {
    commit(mutationTypes.SET_USER, user);

    await dispatch(types.UPDATE_TOKEN, user);

    if (user) {
      // todo: fetch user info from back (hasTwitter)
      await dispatch(types.FETCH_USER_INFO);
    } else {
      localStorage.removeItem("twitter_username");
    }
  },
  async [types.FETCH_USER_INFO]({ commit }) {
    const userInfo = (await axios.get<UserInfo>("user-info")).data;
    commit(mutationTypes.SET_USER_INFO, userInfo);

    if (userInfo.twitterUsername) {
      localStorage.setItem("twitter_username", userInfo.twitterUsername);
    } else {
      localStorage.removeItem("twitter_username");
    }
  },
  async [types.UPDATE_TOKEN]({ commit }, user: User | null) {
    if (user) {
      const token = await user.getIdToken();
      commit(mutationTypes.SET_ID_TOKEN, token);
      localStorage.setItem("id_token", token);
    } else {
      localStorage.removeItem("id_token");
    }
  },
  async [types.REFRESH_STALE_TOKEN]({ dispatch, getters }) {
    const user = (getters.authUser as AuthUser)?.user;
    if (user) {
      await dispatch(types.UPDATE_TOKEN, user);
    } else {
      await dispatch(types.SIGN_OUT);
    }
  },
  async [types.SIGN_IN]({ dispatch }, { email, password }: { email: string; password: string }) {
    const result = await signInWithEmailAndPassword(getAuth(), email, password);
    await dispatch(types.FETCH_USER, result.user);
  },
  async [types.SIGN_OUT]({ commit }) {
    commit(mutationTypes.RESET_STATE);
    await signOut(getAuth());
  },
  async [types.FETCH_CAN_HAVE_MORE_USERS]({ commit }) {
    try {
      const result = (await axios.get<boolean>("can-have-more-users")).data;
      commit(mutationTypes.SET_CAN_HAVE_MORE_USERS, result);
    } catch {
      commit(mutationTypes.SET_CAN_HAVE_MORE_USERS, false);
    }
  },
  async [types.SIGN_UP_WITH_EMAIL]({ dispatch }, { email, password }: { email: string; password: string }) {
    // todo: move most of this to a service?
    try {
      const result = (await axios.post<EmailSignUpResult>("sign-up", { email, password })).data;
      if (result.success) {
        await dispatch(types.SIGN_IN, { email, password });
      }
    } catch (error) {
      // todo
    }
    // call API method and register user there... can be more secure, I think, and we can do more custom logic with creating Users
    // then return here and sign in
  },
  async [types.SIGN_UP_WITH_TWITTER]({ dispatch }, { oauth_token, oauth_verifier }: { oauth_token: string; oauth_verifier: string }) {
    const loginResult = await getAccessToken(oauth_token, oauth_verifier);

    // use access_token and access_secret to login to firebase
    const oauthCredential = TwitterAuthProvider.credential(loginResult.oauth_token, loginResult.oauth_token_secret);
    const userCredential = await signInWithCredential(getAuth(), oauthCredential);

    await dispatch(types.TWITTER_AUTH_POST_HANDLING, { oauthCredential, userCredential });
  },
  async [types.SIGN_IN_WITH_TWITTER](
    { dispatch, getters },
    { oauth_token, oauth_verifier }: { oauth_token: string; oauth_verifier: string }
  ) {
    if (!getters.hasTwitter) {
      await dispatch(types.SIGN_UP_WITH_TWITTER, { oauth_token, oauth_verifier });
    } else {
      const loginResult = await getAccessToken(oauth_token, oauth_verifier);
      const credential = TwitterAuthProvider.credential(loginResult.oauth_token, loginResult.oauth_token_secret);
      const userCredential = await signInWithCredential(getAuth(), credential);
      await dispatch(types.FETCH_USER, userCredential.user);
    }
  },
  async [types.LINK_TWITTER]({ dispatch, state }, { oauth_token, oauth_verifier }: { oauth_token: string; oauth_verifier: string }) {
    const loginResult = await getAccessToken(oauth_token, oauth_verifier);

    // use token and secret to link Twitter account to Firebase
    const oauthCredential = TwitterAuthProvider.credential(loginResult.oauth_token, loginResult.oauth_token_secret);
    const userCredential = await linkWithCredential(state.authUser.user as User, oauthCredential);

    await dispatch(types.TWITTER_AUTH_POST_HANDLING, { oauthCredential, userCredential });
  },
  async [types.TWITTER_AUTH_POST_HANDLING](
    { dispatch },
    { oauthCredential, userCredential }: { oauthCredential: OAuthCredential; userCredential: UserCredential }
  ) {
    await dispatch(types.UPDATE_TOKEN, userCredential.user);

    // send Twitter credentials to back to store for user
    await axios.post(`users/add-twitter`, oauthCredential);
    await dispatch(types.FETCH_USER, userCredential.user);
  },
  async [types.GENERATE_AUTH_LINK]({ commit }) {
    const authLink = (await getAuthLink("authenticate")).data;
    commit(mutationTypes.SET_AUTH_LINK, authLink);
  },

  // TWEETERS
  async [types.FETCH_TRACKED_TWEETERS]({ commit }) {
    commit(mutationTypes.START_LOADING_TWEETERS);
    try {
      const users = (await axios.get<UserV2[]>("tracked-users")).data;
      commit(mutationTypes.SET_TRACKED_USERS, users);
    } finally {
      commit(mutationTypes.END_LOADING_TWEETERS);
    }
  },
  async [types.START_TRACKING_USER]({ commit }, twitterHandle: string) {
    commit(mutationTypes.START_LOADING_TWEETERS);
    try {
      const newUser = (await axios.post<UserV2>("tracked-users", { twitterHandle })).data;
      if (newUser) {
        commit(mutationTypes.ADD_TRACKED_USER, newUser);
      } else {
        // todo: display failed notification?
      }
    } finally {
      commit(mutationTypes.END_LOADING_TWEETERS);
    }
  },
  async [types.STOP_TRACKING_USER]({ commit }, twitterId: string) {
    commit(mutationTypes.REMOVE_TRACKED_USER, twitterId);
    await axios.delete(`tracked-users/${twitterId}`);
  },

  // LISTS
  async [types.FETCH_LISTS]({ commit }) {
    commit(mutationTypes.START_LOADING_LISTS);
    try {
      const lists = (await axios.get<List[]>("lists")).data;
      commit(mutationTypes.SET_LISTS, lists);

      const trackedList = lists.filter((list) => list.isTracked)[0];
      commit(mutationTypes.SET_TRACKED_LIST, trackedList);
    } finally {
      commit(mutationTypes.END_LOADING_LISTS);
    }
  },
  async [types.TRACK_LIST]({ commit, state }, list: List) {
    const oldList = state.trackedList;
    const newList = { ...list, isTracked: true } as List;

    const oldLists = [...(state.lists ?? [])];
    const newLists = oldLists.map((x) => (x.id === newList.id ? newList : { ...x, isTracked: false }));

    const oldInfo = state.userInfo;

    try {
      commit(mutationTypes.SET_TRACKED_LIST, newList);
      commit(mutationTypes.SET_LISTS, newLists);
      commit(mutationTypes.SET_USER_INFO, { ...oldInfo, isTrackingAnyLists: true } as UserInfo);
      await axios.post("tracked-lists", list);
    } catch (error) {
      // revert state change
      commit(mutationTypes.SET_LISTS, oldLists);
      commit(mutationTypes.SET_TRACKED_LIST, oldList);
      commit(mutationTypes.SET_USER_INFO, { ...oldInfo });
    }
  },

  // SCREENSHOTS
  async [types.FETCH_TWEETS]({ commit, getters }) {
    commit(mutationTypes.START_LOADING_TWEETS);
    let count = 0;
    try {
      const nextPageToken = getters.nextTweetPageToken;
      const result = nextPageToken
        ? (await axios.get<TweetQueryResult>(`tweets?pagination_token=${nextPageToken}`)).data
        : (await axios.get<TweetQueryResult>("tweets")).data;

      const currentTweets = getters.tweets;
      const resultTweets = (result.tweets as Tweet[]).map((t) => ({ ...t, publicUrl: `${apiUrl}/screenshots/${t.publicUrl}` }));

      commit(mutationTypes.SET_TWEETS, [...currentTweets, ...resultTweets]);
      commit(mutationTypes.SET_NEXT_PAGE_TOKEN, result.nextPage);

      count = result.tweets.length;
    } finally {
      commit(mutationTypes.END_LOADING_TWEETS);

      if (count === 0) {
        commit(mutationTypes.SET_NO_MORE_TWEETS);
      }
    }
  },
  async [types.REPORT_MISSING_SCREENSHOT]({ commit, getters }, tweet: Tweet) {
    // remove offending tweet
    const currentTweets = getters.tweets as Tweet[];
    const filteredTweets = currentTweets.filter((t) => t.id !== tweet.id);
    commit(mutationTypes.SET_TWEETS, filteredTweets);

    await axios.post(`tweets/missing`, tweet);
  },

  // MISC
  async [types.SHOW_NOTIFICATION](
    { commit, dispatch },
    { notification, milliseconds }: { notification: StateNotification; milliseconds: number }
  ) {
    commit(mutationTypes.START_NOTIFICATION, notification);
    await new Promise((resolve) => setTimeout(resolve, milliseconds));
    await dispatch(types.HIDE_NOTIFICATION);
  },
  [types.HIDE_NOTIFICATION]({ commit }) {
    commit(mutationTypes.END_NOTIFICATION);
  },
};
