<!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
  <!--
    This example requires updating your template:

    ```
    <html class="h-full">
    <body class="h-full">
    ```
  -->
  <div>
    <SideBar :show="sidebarOpen" @hide="sidebarOpen = false" />

    <div class="md:pl-64">
      <div class="max-w-4xl mx-auto flex flex-col md:px-8 xl:px-0">
        <TopBar @openSidebar="sidebarOpen = true" @searchTextChanged="onSearchTextChanged" />

        <main class="flex-1">
          <div class="py-6">
            <div class="px-4 sm:px-6 md:px-0">
              <h1 class="text-2xl font-semibold text-gray-900">Tracked Lists</h1>
            </div>
            <div class="px-4 sm:px-6 md:px-0">
              <TrackedLists v-if="userIsCurrent" :searchText="searchText" />
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, defineComponent, computed } from "vue";
import TrackedLists from "../components/TrackedLists.vue";
import SideBar from "../components/SideBar.vue";
import TopBar from "../components/TopBar.vue";
import { useStore } from "../store";

export default defineComponent({
  name: "Tracked Accounts",
  components: {
    TrackedLists,
    SideBar,
    TopBar,
  },
  setup() {
    const sidebarOpen = ref(false);
    const searchText = ref("");
    const store = useStore();

    return {
      sidebarOpen,
      searchText,
      userIsCurrent: computed(() => !!store.getters.authUser?.user && store.getters.authenticated),
    };
  },
  methods: {
    onSearchTextChanged(value) {
      this.searchText = value;
    },
  },
});
</script>
