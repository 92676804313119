import { createApp } from "vue";
import { store, key } from "./store";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "./index.css";
import { initializeApp } from "firebase/app";
import { onAuthStateChanged, getAuth, onIdTokenChanged } from "firebase/auth";
import { Dispatcher } from "./store/actions";
import { firebaseConfig } from "./config";

initializeApp(firebaseConfig);

const dispatcher = new Dispatcher(store);

onAuthStateChanged(getAuth(), (user) => dispatcher.fetchUser(user));
onIdTokenChanged(getAuth(), (user) => dispatcher.updateToken(user));

const app = createApp(App);
app.use(router);
app.use(store, key);
app.mount("#app");
