<template>
  <div class="max-w-md relative w-full space-y-8">
    <div class="relative">
      <img class="mx-auto h-16 w-auto" src="../assets/logo-horizontal.svg" alt="rkive" />
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">Create a new account</h2>
      <p class="mt-2 text-center text-sm text-gray-600">
        Or
        {{ " " }}
        <router-link to="/sign-in" class="font-medium text-blue-600 hover:text-blue-500"> sign-in to an existing account</router-link>
      </p>
    </div>

    <form class="space-y-3" action="#" @submit.prevent="submit">
      <div>
        <p :class="[emailError ? '' : 'invisible', 'text-sm text-red-600 text-left inline-block w-full']" id="email-error">
          {{ emailErrorMessage }}
        </p>
        <div class="rounded-md shadow-sm">
          <div class="-mb-px relative">
            <label for="email-address" class="sr-only">Email</label>
            <input
              @focus="clearEmailError"
              v-model="form.email"
              id="email"
              name="email"
              type="email"
              autocomplete="email"
              required=""
              :class="[
                emailError
                  ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
                  : 'border-gray-300 placeholder-gray-500 text-gray-900 focus:ring-blue-500 focus:border-blue-500',
                'appearance-none rounded-none relative block w-full px-3 py-2 border rounded-t-md shadow-sm focus:outline-none focus:z-10 sm:text-sm',
              ]"
              placeholder="Email"
            />
            <div :class="[emailError ? '' : 'invisible', 'absolute bottom-3 right-0 pr-2 flex items-center pointer-events-none']">
              <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
            </div>
          </div>

          <div class="relative">
            <label for="password" class="sr-only">Password</label>
            <input
              @focus="clearPasswordError"
              v-model="form.password"
              id="password"
              name="password"
              type="password"
              autocomplete="current-password"
              required=""
              :class="[
                passwordError
                  ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
                  : 'border-gray-300 placeholder-gray-500 text-gray-900 focus:ring-blue-500 focus:border-blue-500',
                'appearance-none rounded-none relative block w-full px-3 py-2 border rounded-b-md shadow-sm focus:outline-none focus:z-10 sm:text-sm',
              ]"
              placeholder="Password"
            />
            <div :class="[passwordError ? '' : 'invisible', 'absolute bottom-3 right-0 pr-2 flex items-center pointer-events-none']">
              <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
            </div>
          </div>
        </div>
        <p :class="[passwordError ? '' : 'invisible', 'text-sm text-red-600 text-left inline-block w-full']" id="password-error">
          {{ passwordErrorMessage }}
        </p>
      </div>

      <div>
        <button
          type="submit"
          :disabled="continuing"
          class="
            w-full
            flex
            justify-center
            py-2
            px-4
            border border-transparent
            rounded-md
            shadow-sm
            text-sm
            font-medium
            text-white
            bg-blue-600
            hover:bg-blue-700
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
          "
        >
          <span v-if="!continuing">Continue</span>
          <RefreshIcon v-if="continuing" class="h-5 w-5 animate-spin" />
        </button>
      </div>
    </form>

    <!-- Or Continue with Twitter -->
    <div class="mt-6" v-if="authLink">
      <div class="relative">
        <div class="absolute inset-0 flex items-center">
          <div class="w-full border-t border-gray-300" />
        </div>
        <div class="relative flex justify-center text-sm">
          <span class="px-2 bg-gray-50 text-gray-500"> Or </span>
        </div>
      </div>

      <div class="mt-6">
        <div>
          <a
            :href="authLink"
            class="
              w-full
              inline-flex
              justify-center
              py-2
              px-4
              border border-gray-300
              rounded-md
              shadow-sm
              bg-white
              text-sm
              font-medium
              text-gray-500
              hover:bg-gray-50
            "
          >
            <span class="sr-only">Continue with Twitter</span>
            <svg class="w-5 h-5 mr-2" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
              <path
                d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84"
              />
            </svg>
            Continue with Twitter
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import { getAuth, createUserWithEmailAndPassword, AuthErrorCodes } from "firebase/auth";
import { ExclamationCircleIcon, RefreshIcon } from "@heroicons/vue/solid";
import { useStore } from "../store";

export default defineComponent({
  components: {
    ExclamationCircleIcon,
    RefreshIcon,
  },
  setup() {
    const emailError = ref(false);
    const passwordError = ref(false);
    const continuing = ref(false);

    const store = useStore();

    return {
      form: {
        email: "",
        password: "",
      },
      emailError,
      emailErrorMessage: "",
      passwordError,
      passwordErrorMessage: "",
      continuing,
      authLink: computed(() => store.state.authLink),
    };
  },
  methods: {
    async submit() {
      this.continuing = true;
      createUserWithEmailAndPassword(getAuth(), this.form.email, this.form.password)
        .then(() => this.$router.push("/register/grant-twitter-access"))
        .catch((error) => {
          if (error.code === AuthErrorCodes.EMAIL_EXISTS) {
            this.emailError = true;
            this.emailErrorMessage = "An account with that email already exists.";
          } else if (error.code === AuthErrorCodes.INVALID_EMAIL) {
            this.emailError = true;
            this.emailErrorMessage = "That email is invalid.";
          } else if (error.code === AuthErrorCodes.WEAK_PASSWORD) {
            this.passwordError = true;
            this.passwordErrorMessage = "That password is weak sauce.";
          } else if (error.code === AuthErrorCodes.INVALID_PASSWORD) {
            this.passwordError = true;
            this.passwordErrorMessage = "That password is not valid.";
          }
        })
        .finally(() => (this.continuing = false));
    },
    clearEmailError() {
      this.emailError = false;
      this.emailErrorMessage = "";
    },
    clearPasswordError() {
      this.passwordError = false;
      this.passwordErrorMessage = "";
    },
  },
});
</script>
