import { RouteLocationNormalized, NavigationGuardNext, RouteLocationRaw } from "vue-router";
import { typedStore } from "@/store";

export default function requireTwitter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void {
  if (typedStore.getters.hasTwitter) {
    next();
  } else {
    const location = { path: "/register/grant-twitter-access", query: { from: to.path } } as RouteLocationRaw;
    next(location);
  }
}
