<!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
-->
<template>
  <ul role="list" class="max-w-xl">
    <li v-for="tweet in tweets" :key="tweet.id" class="relative">
      <div class="group block w-full aspect-h-7">
        <a :href="tweet.publicUrl" class="object-contain inset-0 focus:outline-none">
          <img
            :src="tweet.publicUrl"
            @error="screenshotLoadError(tweet)"
            alt=""
            class="object-contain pointer-events-auto group-hover:opacity-75"
          />
          <span class="sr-only">View details for {{ tweet.id }}</span>
        </a>
      </div>
    </li>
    <li v-if="loading && hasTrackedList" class="relative">
      <content-loader viewBox="0 0 300 200" primaryColor="#f3f3f3" secondaryColor="#cccccc">
        <rect x="6" y="0" rx="6" ry="6" width="290" height="150" />
      </content-loader>
    </li>
    <li v-if="!initialLoad && !loading && !hasTrackedList" class="relative w-96">
      <router-link to="/tracked-lists">
        <button
          type="button"
          class="
            relative
            block
            w-full
            border-2 border-gray-300 border-dashed
            rounded-lg
            p-12
            text-center
            hover:border-gray-400
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
          "
        >
          <PlusIcon class="h-8 w-8 font-medium text-gray-400 mx-auto"></PlusIcon>
          <span class="mt-8 block text-sm font-medium text-gray-900"> Track a Twitter List to get started... </span>
        </button>
      </router-link>
    </li>
    <li v-if="!loading && noMoreTweets && hasTrackedList" class="relative w-96">
      <div
        type="button"
        class="
          relative
          block
          w-full
          border-2 border-gray-300 border-dashed
          rounded-lg
          p-12
          text-center
          hover:border-gray-400
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
        "
      >
        <ClockIcon class="h-8 w-8 font-medium text-gray-400 mx-auto"></ClockIcon>
        <span class="mt-8 block text-sm font-medium text-gray-900"> It might take up to 15 minutes for tweets to show up here... </span>
      </div>
    </li>
    <VueEternalLoading v-if="!loading && hasTrackedList && !noMoreTweets" :load="load"></VueEternalLoading>
  </ul>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import { useStore } from "../store";
import { Dispatcher } from "../store/actions";
import { ContentLoader } from "vue-content-loader";
import { VueEternalLoading } from "@ts-pro/vue-eternal-loading";
import { debounce } from "lodash";
import { PlusIcon, ClockIcon } from "@heroicons/vue/outline";

export default defineComponent({
  props: ["searchText"],
  components: {
    ContentLoader,
    VueEternalLoading,
    PlusIcon,
    ClockIcon,
  },
  setup(props) {
    const store = useStore();
    const dispatcher = new Dispatcher(store);

    const initialLoad = ref(true);

    return {
      tweets: computed(() => {
        // todo: refactor this to a *.ts service
        if (!props.searchText) {
          return store.state.tweets;
        }

        const lowercaseSearchText = props.searchText.toLowerCase();
        return store.state.tweets.filter(
          (t) =>
            t.text.toLowerCase().includes(lowercaseSearchText) ||
            t.name.toLowerCase().includes(lowercaseSearchText) ||
            t.username.toLowerCase().includes(lowercaseSearchText)
        );
      }),
      loading: computed(() => store.state.loadingTweets),
      fetchTweets: () => dispatcher.fetchScreenshots(),
      fetchUserInfo: () => dispatcher.fetchUserInfo(),
      screenshotLoadError: (tweet) => dispatcher.reportMissingScreenshot(tweet),
      hasTrackedList: computed(() => !!store.state.userInfo.isTrackingAnyLists),
      noMoreTweets: computed(() => store.state.noMoreTweets),
      initialLoad,
    };
  },
  created() {
    this.fetchTweets();
    this.fetchUserInfo().then(() => (this.initialLoad = false));
  },
  methods: {
    load({ loaded }) {
      const fetchThenLoaded = () => {
        if (!this.noMoreTweets) {
          this.fetchTweets().then(() => loaded());
        }
      };

      debounce(fetchThenLoaded, 500)();
    },
  },
});
</script>
