import { User } from "firebase/auth";
import { GetterTree } from "vuex";
import { State, AuthUser } from "./state";
import { UserV2 } from "twitter-api-v2";
import { Tweet } from "@/models/tweet";

export type Getters = {
  authUser(state: State): AuthUser;
  idToken(state: State): string;
  firebaseUser(state: State): User | null;
  authenticated(state: State): boolean;
  trackedUsers(state: State): UserV2[];
  hasTwitter(state: State): boolean;
  nextTweetPageToken(state: State): string | undefined;
  tweets(state: State): Tweet[];
};

export const getters: GetterTree<State, State> & Getters = {
  authUser: (state) => state.authUser,
  idToken: (state) => state.authUser.idToken,
  firebaseUser: (state) => state.authUser.user,
  authenticated: (state) => !!state.authUser.idToken,
  trackedUsers: (state) => state.trackedUsers ?? [],
  hasTwitter: (state) => !!state.userInfo.twitterUsername,
  nextTweetPageToken: (state) => state.tweetsNextPage,
  tweets: (state) => state.tweets,
};
