<template>
  <div class="flex items-center justify-center w-full h-screen">
    <!-- Loading Indicator -->
    <div class="flex justify-center items-center space-x-1 text-sm text-gray-700">
      <svg fill="none" class="w-6 h-6 animate-spin" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <path
          clip-rule="evenodd"
          d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
          fill="currentColor"
          fill-rule="evenodd"
        />
      </svg>

      <div>Loading ...</div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Dispatcher, useStore } from "../store";

export default defineComponent({
  setup() {
    const store = useStore();
    const dispatcher = new Dispatcher(store);
    return {
      signIn: async (oauth_token, oauth_verifier) => await dispatcher.signInWithTwitter(oauth_token, oauth_verifier),
    };
  },
  created() {
    const { oauth_token, oauth_verifier } = this.$route.query;

    // sign up with twitter account
    this.signIn(oauth_token, oauth_verifier)
      .then(() => this.$router.push("/screenshots"))
      .catch((error) => {
        // todo: log, redirect to error page?
        console.error("Unable to sign in via Twitter");
      });
  },
});
</script>
