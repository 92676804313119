import { FirebaseOptions } from "firebase/app";
import config from "./config.json";

export const apiUrl = config.API_URL;
export const twitter = {
  appKey: config.TWITTER_API_KEY || process.env.VUE_APP_TWITTER_API_KEY || "",
  appSecret: config.TWITTER_API_KEY_SECRET || process.env.VUE_APP_TWITTER_API_KEY_SECRET || "",
};

export const firebaseConfig = {
  apiKey: config.FIREBASE_API_KEY || process.env.VUE_APP_FIREBASE_API_KEY || "",
  appId: config.FIREBASE_APP_ID || process.env.VUE_APP_FIREBASE_APP_ID || "",
  authDomain: config.FIREBASE_AUTH_DOMAIN || process.env.VUE_APP_FIREBASE_AUTH_DOMAIN || "",
  measurementId: config.FIREBASE_MEASUREMENT_ID || process.env.VUE_APP_FIREBASE_MEASUREMENT_ID || "",
  messagingSenderId: config.FIREBASE_MESSAGING_SENDER_ID || process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID || "",
  projectId: config.FIREBASE_PROJECT_ID || process.env.VUE_APP_FIREBASE_PROJECT_ID || "",
  storageBucket: config.FIREBASE_STORAGE_BUCKET || process.env.VUE_APP_FIREBASE_STORAGE_BUCKET || "",
} as FirebaseOptions;
