import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import { requireAuth, requireTwitter, canHaveMoreUsers, requireAuthLink } from "../auth";

import Landing from "../views/Landing.vue";
import SignIn from "../views/SignIn.vue";
import SignOut from "../views/SignOut.vue";
import Screenshots from "../views/Screenshots.vue";
import TrackedAccounts from "../views/TrackedAccounts.vue";
import Register from "../views/Register.vue";
import RegisterStart from "../components/RegisterStart.vue";
import KeepInTouch from "../components/KeepInTouch.vue";
import TwitterCallback from "../views/TwitterCallback.vue";
import TwitterSignIn from "../views/TwitterSignIn.vue";
import GrantTwitterAccess from "../components/GrantTwitterAccess.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Landing",
    component: Landing,
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: SignIn,
    beforeEnter: [requireAuthLink],
  },
  {
    path: "/sign-out",
    name: "SignOut",
    component: SignOut,
  },
  {
    path: "/register",
    name: "Register",
    redirect: "/register/start",
    component: Register,
    children: [
      {
        path: "start",
        name: "Register Start",
        component: RegisterStart,
        beforeEnter: [canHaveMoreUsers, requireAuthLink],
      },
      {
        path: "keep-in-touch",
        name: "Keep in Touch",
        component: KeepInTouch,
      },
      {
        path: "grant-twitter-access",
        name: "Grant Twitter Access",
        component: GrantTwitterAccess,
        beforeEnter: [requireAuth, requireAuthLink],
      },
    ],
  },
  {
    path: "/twitter-callback",
    name: "Twitter Callback",
    component: TwitterCallback,
  },
  {
    path: "/twitter-sign-in",
    name: "Twitter Sign In",
    component: TwitterSignIn,
  },
  {
    path: "/screenshots",
    name: "Screenshots",
    component: Screenshots,
    beforeEnter: [requireAuth, requireTwitter],
  },
  {
    path: "/tracked-lists",
    name: "TrackedAccounts",
    component: TrackedAccounts,
    beforeEnter: [requireAuth, requireTwitter],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
