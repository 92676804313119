import { RouteLocationNormalized, NavigationGuardNext } from "vue-router";
import { Dispatcher, typedStore } from "@/store";

export default async function requireAuthLink(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> {
  if (typedStore.state.authLink) {
    next();
  } else {
    const dispatcher = new Dispatcher(typedStore);
    try {
      await dispatcher.generateAuthLink();
      next();
    } catch (error) {
      next();
    }
  }
}
