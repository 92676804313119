import axios from "axios";
import { typedStore } from "../store";
import { apiUrl } from "../config";
import { getAuth } from "firebase/auth";

export const apiAxios = axios.create({ baseURL: apiUrl });

export const authenticatedApiAxios = axios.create({ baseURL: apiUrl });
authenticatedApiAxios.interceptors.request.use(
  async (config) => {
    if (!typedStore.getters.authenticated) {
      return config;
    }

    const user = typedStore.getters.authUser?.user;
    if (!user) {
      return config;
    }

    const token = await user.getIdToken();
    if (token && config && config.headers) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // todo: log
    // todo: redirect the user to a page?
    return Promise.reject(error);
  }
);
