import { List } from "@/models/list";
import { Tweet } from "@/models/tweet";
import { User } from "firebase/auth";
import { UserV2 } from "twitter-api-v2";

export interface AuthUser {
  user: User | null;
  idToken: string;
}

export interface UserInfo {
  twitterUsername?: string;
  isTrackingAnyLists?: boolean;
}

export interface StateNotification {
  heading: string;
  message: string;
}

export interface State {
  authUser: AuthUser;
  userInfo: UserInfo;
  lists?: List[];
  trackedList?: List;
  loadingLists: boolean;
  trackedUsers: UserV2[];
  tweets: Tweet[];
  tweetsNextPage?: string;
  noMoreTweets: boolean;
  notification: StateNotification | null;
  loadingTweeters: boolean;
  loadingTweets: boolean;
  canHaveMoreUsers: boolean;
  authLink?: string;
}

export function defaultState(): State {
  return {
    authUser: {
      user: null,
      idToken: localStorage.getItem("id_token") ?? "",
    },
    userInfo: {
      twitterUsername: localStorage.getItem("twitter_username") ?? undefined,
    },
    trackedUsers: [],
    tweets: [],
    noMoreTweets: false,
    notification: null,
    loadingTweeters: false,
    loadingTweets: false,
    loadingLists: false,
    canHaveMoreUsers: false,
  };
}
