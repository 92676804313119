<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 md:h-screen flex items-center">
    <!-- We've used 3xl here, but feel free to try other max-widths based on your needs -->
    <div class="max-w-3xl mx-auto min-w-full">
      <!-- Content goes here -->
      <!-- Be sure to use this with a layout container that is full-width on mobile -->
      <div class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
        <div class="px-4 py-5 sm:px-6">
          <!-- Content goes here -->
          <!-- We use less vertical padding on card headers on desktop than on body sections -->
          <nav aria-label="Progress">
            <ol role="list" class="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
              <li v-for="(step, stepIdx) in steps" :key="step.name" class="relative md:flex-1 md:flex">
                <div v-if="step.status === 'complete'" class="group flex items-center w-full">
                  <span class="px-6 py-4 flex items-center text-sm font-medium">
                    <span class="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-blue-600 rounded-full">
                      <CheckIcon class="w-6 h-6 text-white" aria-hidden="true" />
                    </span>
                    <span class="ml-4 text-sm font-medium text-gray-900">{{ step.name }}</span>
                  </span>
                </div>
                <div v-else-if="step.status === 'current'" class="px-6 py-4 flex items-center text-sm font-medium" aria-current="step">
                  <span class="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-blue-600 rounded-full">
                    <span class="text-blue-600">{{ step.id }}</span>
                  </span>
                  <span class="ml-4 text-sm font-medium text-blue-600">{{ step.name }}</span>
                </div>
                <div v-else class="group flex items-center">
                  <span class="px-6 py-4 flex items-center text-sm font-medium">
                    <span class="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full">
                      <span class="text-gray-500">{{ step.id }}</span>
                    </span>
                    <span class="ml-4 text-sm font-medium text-gray-500">{{ step.name }}</span>
                  </span>
                </div>
                <template v-if="stepIdx !== steps.length - 1">
                  <!-- Arrow separator for lg screens and up -->
                  <div class="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                    <svg class="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                      <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor" stroke-linejoin="round" />
                    </svg>
                  </div>
                </template>
              </li>
            </ol>
          </nav>
        </div>
        <div class="px-4 py-5 sm:p-6">
          <!-- Content goes here -->
          <div class="max-w-7xl mx-auto text-center pt-8 pb-0 px-4 sm:px-6 lg:pb-4 lg:px-8">
            <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              <span class="block text-blue-600">Almost finished!</span>
            </h2>

            <div class="mt-8 flex justify-center">
              <a
                :href="authLink"
                class="
                  max-w-sm
                  inline-flex
                  justify-center
                  py-2
                  px-4
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  bg-blue-600
                  text-sm
                  font-medium
                  text-white
                  hover:bg-blue-800
                "
              >
                <span class="sr-only">Link Twitter Account</span>
                <svg class="w-5 h-5 mr-2" aria-hidden="true" fill="white" viewBox="0 0 20 20">
                  <path
                    d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84"
                  />
                </svg>
                Link Twitter Account
              </a>
            </div>

            <p class="mt-16 text-lg leading-6 text-left">
              Don't worry, <span class="text-blue-600 font-bold">rkive</span> will only use your Twitter account to:
            </p>
            <ul role="list" class="divide-y divide-gray-200 text-left">
              <li class="py-3 lg:py-5">
                <div class="relative focus-within:ring-2 focus-within:ring-blue-500">
                  <h3 class="text-sm font-semibold text-gray-800">
                    <span class="absolute inset-0" aria-hidden="true" />
                    View your Twitter Lists
                  </h3>
                  <p class="mt-1 text-sm text-gray-600 line-clamp-2">
                    Your Twitter Lists determine whose Tweets we
                    <span class="text-blue-600 font-bold">rkive</span> for you.
                  </p>
                </div>
              </li>
              <li class="py-3 lg:py-5">
                <div class="relative focus-within:ring-2 focus-within:ring-blue-500">
                  <h3 class="text-sm font-semibold text-gray-800">
                    <span class="absolute inset-0" aria-hidden="true" />
                    Download Tweets
                  </h3>
                  <p class="mt-1 text-sm text-gray-600 line-clamp-2">
                    We use <em>your</em> account to download the Tweets that <em>you</em> want us to
                    <span class="text-blue-600 font-bold">rkive</span>.
                  </p>
                </div>
              </li>
              <li class="py-3 lg:py-5">
                <div class="relative focus-within:ring-2 focus-within:ring-blue-500">
                  <h3 class="text-sm font-semibold text-gray-800">
                    <span class="absolute inset-0" aria-hidden="true" />
                    Download basic Twitter User info
                  </h3>
                  <p class="mt-1 text-sm text-gray-600 line-clamp-2">
                    Limited to <em>username</em>, <em>name</em>, and <em>profile photo</em>.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckIcon } from "@heroicons/vue/solid";
import { computed, defineComponent } from "@vue/runtime-core";
import { useStore } from "../store";

const steps = [
  { id: "01", name: "Register", href: "#", status: "complete" },
  { id: "02", name: "Link Twitter", href: "#", status: "current" },
];

export default defineComponent({
  components: {
    CheckIcon,
  },
  setup() {
    const store = useStore();

    return {
      steps,
      authLink: computed(() => store.state.authLink),
    };
  },
});
</script>
